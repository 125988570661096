








import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    title: String,
    text: String,
    type: String, // 'info' or 'how'
  },
})
export default class InfoBoxComponent extends Vue {
}
