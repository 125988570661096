























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
// @ is an alias to /src
import ViewLayoutComponent from '@/components/ViewLayoutComponent.vue';
import InfoBoxComponent from '@/components/InfoBoxComponent.vue';
import { flavorData } from '@/flavor';
import { FlavorData } from '@/flavor/types';

@Component({
  components: {
    ViewLayoutComponent,
    InfoBoxComponent,
  },
})
export default class Support extends Vue {
  flavorData: FlavorData = flavorData;
  version = process.env.VUE_APP_VERSION;
}
